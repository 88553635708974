import Vuex from 'vuex'
import * as modules from './modules'

export default new Vuex.Store({
  modules,
  state: {
    test: 'ok'
  },
  mutations: {},
  actions: {}
})
