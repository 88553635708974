<template>
  <div class="e-page adminAuth">
    <div class="adminAuth__container">
      <h1>Admin</h1>
      <sign-up-form @submit="signup" />
    </div>
  </div>
</template>

<script>
import SignUpForm from '../components/block/sign-up-form'
// import axiosAdmin from "../scripts/axios-admin";

export default {
  name: 'auth',
  components: {
    SignUpForm
  },
  methods: {
    signup(params) {
      this.$store
        .dispatch('auth/signup', params)
        .then(() => {
          this.$store.dispatch('notifications/present', {
            type: 'Notice',
            text: `Vous êtes connectés avec l'email ${params.email}`
          })
          this.$router.push('/admin/configuration')
        })
        .catch(e => {
          this.$store.dispatch('notifications/present', {
            type: 'error',
            text: 'Vérifiez votre email/mot de passe'
          })
          console.warn(e)
        })
    }
  }
}
</script>

<style lang="scss">
.adminAuth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  form {
    margin: var(--spacing-m) 0;

    input {
      margin-top: var(--spacing-l);
    }
  }

  &__container {
    width: 235px;
  }

  &__link {
    display: block;
    margin-top: var(--spacing-l);
  }
}
</style>
