import fetcher from '../assets/scripts/fetcher'

export default {
  namespaced: true,
  state: {},

  mutations: {
    updateConfiguration(state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    }
  },

  actions: {
    async fetch({ commit }) {
      const { data } = await fetcher.get('/configurations')
      commit('updateConfiguration', data)
      return data
    }
  }
}
