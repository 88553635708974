import GridItem from '@/components/block/grid-item'
import { mapMutations } from 'vuex'

const intersectionCallbacks = new Map()

const intersectionObserver = new IntersectionObserver(entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting && intersectionCallbacks.has(entry.target)) {
      const callback = intersectionCallbacks.get(entry.target)
      callback(entry)
    }
  })
})

export default {
  components: {
    GridItem
  },
  props: {
    content: {
      type: Object,
      required: () => true
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    canOpenPopin: {
      type: Boolean,
      required: false,
      default: () => true
    },
    fileKey: {
      type: String,
      required: false,
      default: 'originFile'
    }
  },
  data() {
    return {
      isIntersecting: false
    }
  },
  mounted() {
    intersectionCallbacks.set(this.$el, this.onIntersect)
    intersectionObserver.observe(this.$el)
  },
  beforeDestroy() {
    intersectionCallbacks.delete(this.$el)
    intersectionObserver.unobserve(this.$el)
  },
  methods: {
    ...mapMutations({
      setPopinOpen: 'setPopinOpen',
      setPopinContent: 'setPopinContent',
      setPopinSource: 'setPopinSource',
      setMenuOpen: 'setMenuOpen'
    }),
    onIntersect() {
      this.isIntersecting = true
    },
    onClick() {
      if (!this.canOpenPopin) return
      this.$router.push({ hash: 'display' })
      this.setPopinContent(this.content)
      this.setPopinSource('grid')
      this.$nextTick(() => {
        this.setPopinOpen(true)
        this.setMenuOpen(false)
      })
    }
  }
}
