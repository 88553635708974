<template>
  <ul class="adminMenu">
    <li v-for="route in routes.filter(r => r.meta.menuName)" :key="route.name">
      <e-link
        :active="$route.name.includes(route.name)"
        :to="`/admin/${route.path}`"
        >{{ route.meta.menuName }}</e-link
      >
    </li>
    <li>
      <e-button
        @click.prevent.native="logout"
        arrow="before"
        class="adminMenu__logOut"
        >Se déconnecter</e-button
      >
    </li>
  </ul>
</template>

<script>
import routes from '../../routes'

export default {
  data() {
    return {
      routes
    }
  },
  methods: {
    logout() {
      this.$store.commit('auth/logout')
      this.$router.push('/admin/login')
    }
  }
}
</script>

<style lang="scss">
.adminMenu {
  li {
    margin-bottom: var(--spacing-s);
  }

  &__logOut {
    margin-top: var(--spacing-xl);
  }
}
</style>
