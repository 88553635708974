<template>
  <div
    :style="`--column-count:${columnCount};--row-height:${rowHeight}px`"
    class="versionGrid"
  >
    <version-grid-cell
      ref="cellItems"
      v-for="cell in cells"
      :key="cell.uid"
      :item="cell"
      @addingMedia="onAddingMedia"
      @insertingMedia="onInsertingMedia"
      @mediaAdded="onMediaAdded"
      @removingMedia="onCellRemoval"
    />
    <b-popin v-if="showMediaSelection" @cancel="hideMediaSelection">
      <prompt-media-selection
        @confirm="onConfirmSelected"
        @cancel="hideMediaSelection"
      />
    </b-popin>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters /* mapActions */ } from 'vuex'
import VersionGridCell from './version-grid-cell'
import PromptMediaSelection from './prompt-media-selection'
// Based on values in src/components/component/content-grid.vue
// The grid specific values are handled with js, because in the admin ui,
// the grid is not full screen width.
/* eslint-disable-next-line */
const aspects = {
  breakpoints: [0, 400, 640, 768, 960, 1440, 1680],
  ratios: [8.65, 8.95, 9.35, 4.8, 5, 5.2, 5.35].map(x =>
    parseFloat((x / 100).toFixed(4))
  )
}

/* eslint-disable-next-line */
function closest(arr, target) {
  return arr.reduce((prev, curr) =>
    Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
  )
}

function gridIndex(col, row, width) {
  return (row - 1) * width + (col - 1)
}

// Grid items have not unique id, and for proper v-for handling, index by item array index (i) is insuficient
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export default {
  components: {
    VersionGridCell,
    PromptMediaSelection
  },
  props: {
    columnCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      resize: new ResizeObserver(this.onResize),
      rowHeight: 0,
      showMediaSelection: false,
      targetCell: null,
      applyMedia: null,
      cells: []
    }
  },
  computed: {
    ...mapState({
      gridItems(state) {
        switch (this.columnCount) {
          case 5:
            return state.version.gridDesktop.items
          case 3:
            return state.version.gridMobile.items
        }
      },
      pendingGridMedia: state => state.medias.pendingGridMedia
    }),
    ...mapGetters({
      findMediaById: 'medias/findById'
    })
  },
  mounted() {
    this.resize.observe(this.$el)

    if (this.gridItems.length > 0) {
      this.cells = [
        ...this.gridItems.map(e => {
          e.uid = uuidv4()
          return e
        })
      ]
    }

    for (let i = 0; i < this.columnCount; i++) {
      this.addEmptyCell({ col: i + 1, width: 1 })
    }
  },
  beforeDestroy() {
    this.resize.unobserve(this.$el)
  },
  methods: {
    ...mapMutations({
      setPendingGridMedia: 'medias/setPendingGridMedia',
      saveDesktopGridItems: 'version/saveDesktopGridItems',
      saveMobileGridItems: 'version/saveMobileGridItems'
    }),
    onResize(entries) {
      for (const { contentBoxSize, contentRect } of entries) {
        let inlineSize = 0
        if (contentBoxSize) {
          inlineSize = contentBoxSize[0]
            ? contentBoxSize[0].inlineSize
            : contentBoxSize.inlineSize
        } else {
          inlineSize = contentRect.width
        }

        const closestBreakpoint = closest(aspects.breakpoints, inlineSize)
        const i = aspects.breakpoints.indexOf(closestBreakpoint)
        this.rowHeight = aspects.ratios[i] * inlineSize
      }
    },
    updateCell(target) {
      this.cells.forEach((cell, i) => {
        if (cell.uid === target.uid) {
          if (target.col + target.width > this.columnCount + 1) {
            this.$store.dispatch('notifications/present', {
              type: 'Erreur',
              text: `Vous ne pouvez pas mettre une video de taille ${target.width} ici.`
            })
            this.cells.splice(i, 1)
          } else {
            cell.width = target.width
            cell.height = target.height
            cell.mediaId = target.mediaId
          }
        }
      })
    },
    addEmptyCell({ col, width }) {
      const startColumn = col
      const endColumn = col + width
      const previousColumnItems = this.cells.filter(
        cell => cell.col < startColumn
      )

      for (let i = startColumn; i < endColumn; i++) {
        const hasEmptyCell = this.cells.some(
          cell => cell.col === i && !cell.mediaId
        )

        if (hasEmptyCell) continue

        const [lastNonEmptyCell] = this.cells
          .filter(cell => cell.col === i)
          .sort((a, b) => (a.row > b.row ? -1 : 1))

        if (!lastNonEmptyCell) {
          this.cells.push({
            uid: uuidv4(),
            row: 1,
            col: i,
            width: 1,
            height: 2,
            mediaId: null
          })
          continue
        }

        let row = lastNonEmptyCell.row + lastNonEmptyCell.height

        if (previousColumnItems.length > 0) {
          const matchinYoutubeCell = previousColumnItems.find(
            cell => cell.width === 3 && cell.row === row
          )
          row += matchinYoutubeCell ? matchinYoutubeCell.height : 0
        }

        this.cells.push({
          uid: uuidv4(),
          mediaId: null,
          col,
          row,
          width: 1,
          height: 2
        })
      }
    },
    updateCellPositions(/* skipCells = [] */) {
      this.cells
        .sort((a, b) => {
          return (
            gridIndex(a.col, a.row, this.columnCount) -
            gridIndex(b.col, b.row, this.columnCount)
          )
        })
        .forEach((A, i, arr) => {
          // if (skipCells.some(C => C.col === A.col && C.row === A.row)) return

          const closestPreviousCells = arr.filter((B, j) => {
            if (j >= i) return false
            // Find Overlaping Columns
            const matchColumn =
              B.col < A.col + A.width && B.col + B.width > A.col

            // Find Matching Row
            const matchRow = B.row <= A.row

            return matchColumn && matchRow
          })

          const closests = closestPreviousCells.sort((a, b) => {
            // const compareRow = Math.abs(a.row - A.row) - Math.abs(b.row - A.row)
            // if (compareRow === 0) {
            return b.row + b.height > a.row + a.height ? 1 : -1
            // } else return compareRow
          })

          const closest = closests[0]

          if (closest) {
            A.row = closest.row + closest.height
          } else A.row = 1
        })

      this.$emit('updated')
    },
    onAddingMedia(applyMedia) {
      this.applyMedia = applyMedia
      this.showMediaSelection = true
    },
    onInsertingMedia(targetCell) {
      this.cells.forEach(cell => {
        if (cell.col === targetCell.col && cell.row >= targetCell.row) {
          cell.row += 1
        }
      })

      for (let i = targetCell.col; i < targetCell.col + targetCell.width; i++) {
        this.cells.push({
          uid: uuidv4(),
          row: targetCell.row,
          col: i,
          width: 1,
          height: 2,
          mediaId: null
        })
      }

      this.updateCellPositions()
    },
    onMediaAdded(targetCell) {
      this.updateCell(targetCell)
      this.addEmptyCell(targetCell)
      this.updateCellPositions()
      this.applyMedia = null
    },
    onCellRemoval({ uid }) {
      this.cells = this.cells.filter(cell => cell.uid !== uid)
      this.updateCellPositions()
    },
    async onConfirmSelected() {
      this.applyMedia(this.pendingGridMedia)
      this.hideMediaSelection()
    },
    hideMediaSelection() {
      this.showMediaSelection = false
      this.setPendingGridMedia(null)
    },
    save() {
      const results = this.cells
        .filter(cell => cell.mediaId)
        // remove unecessary keys
        .reduce((acc, cell) => {
          let out = {}
          for (const key in cell) if (key !== 'uid') out[key] = cell[key]
          return [...acc, out]
        }, [])

      if (this.columnCount === 5) this.saveDesktopGridItems(results)
      if (this.columnCount === 3) this.saveMobileGridItems(results)
    }
  }
}
</script>

<style lang="scss">
.versionGrid {
  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);
  grid-auto-rows: var(--row-height);
  column-gap: 10px;
  row-gap: 10px;
}
</style>
