<template>
  <button
    class="adminButton"
    :type="type"
    :disabled="disabled"
    :class="{
      'adminButton--link':
        arrow || layout === 'link' || layout === 'link-arrow',
      'adminButton--linkArrow': arrow && typeof arrow === 'string',
      'adminButton--linkArrowBefore': arrow && arrow === 'before',
      'adminButton--linkArrowAfter': arrow && arrow === 'after'
    }"
  >
    <arrow v-if="arrow === 'before'" />
    <slot />
    <arrow v-if="arrow === 'after'" />
  </button>
</template>

<script>
import Arrow from '../../../../public/img/picto/arrow.svg'

export default {
  components: {
    Arrow
  },
  props: {
    layout: {
      required: false,
      default: 'default'
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    arrow: {
      type: [String, Boolean],
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.adminButton {
  border: 1px solid var(--black);
  padding: var(--spacing-m) var(--spacing-xl);
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-s);
  line-height: 1em;
  vertical-align: bottom;
  margin-top: 25px;

  &[disabled] {
    opacity: 0.25;
    color: var(--black);
    cursor: not-allowed;
  }

  @media (hover: hover) {
    &:not(&[disabled]):hover {
      background-color: var(--black);
      color: var(--white);

      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }

  &--link {
    &Arrow {
      &Before {
        svg {
          transform: rotateZ(-180deg);
          margin-right: 5px;
        }
      }

      &After {
        svg {
          margin-left: 5px;
        }
      }
    }

    &:hover {
      font-weight: 700;
    }
  }
}
</style>
