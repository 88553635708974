import axios from 'axios'

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  header: {
    'Content-Type': 'application/json'
  }
})
const token = localStorage.getItem('Bearer-admin') || false

if (token) {
  axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`
}

export default axiosClient
