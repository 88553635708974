<template>
  <header class="editHeader">
    <e-link :to="backTo" arrow="before">Retour</e-link>
    <span class="editHeader__separator">|</span>
    <h1 class="e-h1"><slot /></h1>
  </header>
</template>

<script>
export default {
  props: {
    backTo: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.editHeader {
  display: flex;
  margin-bottom: 25px;

  &__separator {
    display: block;
    margin: 0 1em;
  }
}
</style>
