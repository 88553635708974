import axiosAdmin from '../scripts/axios-admin'

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    access_token: null
  },
  mutations: {
    setAccessToken(state, payload) {
      state.access_token = payload
      state.isAuthenticated = true
    },
    logout(state) {
      localStorage.removeItem('Bearer-admin')
      state.access_token = null
      state.isAuthenticated = false
    }
  },
  actions: {
    init({ commit }) {
      return new Promise(resolve => {
        const token = localStorage.getItem('Bearer-admin') || false

        if (token) {
          axiosAdmin
            .get('/auth/check')
            .then(() => {
              commit('setAccessToken', token)
              resolve(token)
            })
            .catch(() => {
              commit('logout')
              console.warn('invalid token, logging out')
              resolve(false)
            })
        } else {
          resolve(false)
        }
      })
    },
    login({ commit }, params) {
      return new Promise((resolve, reject) => {
        axiosAdmin
          .post('/auth/local/sign_in', params)
          .then(response => {
            localStorage.setItem('Bearer-admin', response.data.access_token)
            axiosAdmin.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`

            commit('setAccessToken', response.data.access_token)

            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    signup({ commit }, params) {
      return new Promise((resolve, reject) => {
        axiosAdmin
          .post('/auth/local/sign_up', params)
          .then(response => {
            localStorage.setItem('Bearer-admin', response.data.access_token)
            axiosAdmin.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`

            commit('setAccessToken', response.data.access_token)

            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
}
