import axiosAdmin from '../scripts/axios-admin'

export default {
  namespaced: true,
  state: {
    id: -1,
    name: null,
    password: null,
    public: false,
    slug: null,
    canDownload: false,
    createdAt: null,
    updatedAt: null,
    groups: [],
    gridDesktop: {
      width: 5,
      items: []
    },
    gridMobile: {
      width: 3,
      items: []
    },
    startAt: null,
    endAt: null
  },
  getters: {
    startAtToInputs: state =>
      state.startAt ? state.startAt.split(/(?:T|\.)+/) : [null, null],
    endAtToInputs: state =>
      state.endAt ? state.endAt.split(/(?:T|\.)+/) : [null, null]
  },
  mutations: {
    init(state) {
      state.id = -1
      state.name = ''
      state.password = ''
      state.canDownload = false
      state.createdAt = null
      state.updatedAt = null
      state.groups = []
      state.gridDesktop = {
        width: 5,
        items: []
      }
      state.gridMobile = {
        width: 3,
        items: []
      }
      state.startAt = null
      state.endAt = null
      state.archive = null
      state.pressRelease = null
      state.slug = null
    },
    setCurrentVersion(state, payload) {
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          state[key] = payload[key]
        }
      }
    },
    addVersionGroup(state, payload) {
      if (state.groups.some(group => group.id === payload.id)) return
      state.groups = [...state.groups, payload]
    },
    removeVersionGroup(state, payload) {
      if (state.groups.every(group => group.id !== payload.id)) return
      state.groups = state.groups.filter(group => group.id !== payload.id)
    },
    saveDesktopGridItems(state, grid) {
      state.gridDesktop.items = grid
    },
    saveMobileGridItems(state, grid) {
      state.gridMobile.items = grid
    }
  },
  actions: {
    reset({ commit }) {
      commit('init')
    },
    async postAttachments(store, [id, data]) {
      return await axiosAdmin.request({
        data,
        method: 'post',
        url: `/versions/${id}/attachment`,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    },
    async fetch({ commit }, id) {
      const { data } = await axiosAdmin.get(`/versions/${id}`)
      commit('setCurrentVersion', data)
    },
    async updateVersion({ state, dispatch }, formData) {
      console.log(state.id)
      /* eslint-disable-next-line */
      const { createdAt, updatedAt, ...payload } = state
      const { data } = await axiosAdmin.patch(`/versions/${state.id}`, payload)

      await dispatch('postAttachments', [data.id, formData])

      return data
    },
    async createVersion({ state, dispatch }, formData) {
      const payload = Object.entries(state).reduce((acc, entry) => {
        switch (entry[0]) {
          case 'gridDesktopId':
          case 'gridMobileId':
          case 'createdAt':
          case 'updatedAt':
            return acc
          default:
            acc[entry[0]] = entry[1]
            return acc
        }
      }, {})
      const { data } = await axiosAdmin.post(`/versions`, payload)

      await dispatch('postAttachments', [data.id, formData])

      return data
    }
  }
}
