<template>
  <table class="adminEntityList">
    <tr v-if="columns">
      <th v-for="(column, i) in columns" :key="i">{{ column }}</th>
    </tr>
    <slot />
  </table>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: false
    }
  }
}
</script>

<style lang="scss">
.adminEntityList {
  margin-top: 20px;
  // border-top: 1px solid var(--black);
  border-collapse: collapse;
  table-layout: fixed;

  tr {
    border-bottom: 1px solid var(--black);
    td,
    th {
      padding: var(--spacing-m) 0;
    }

    th {
      text-align: left;

      &:last-child {
        text-align: right;
      }
    }
  }
}
</style>
