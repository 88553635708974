<template>
  <div class="rootAdmin" id="rootAdmin">
    <admin-header v-if="showAdminHeader" />
    <router-view v-if="this.$route.name !== 'adminNotFound'" />
    <notification-center class="adminNotificationCenter" />
  </div>
</template>

<script>
import AdminHeader from '../components/shell/header'
import NotificationCenter from '../components/block/notification-center'
import store from '../store'
import { mapState } from 'vuex'

export default {
  components: {
    AdminHeader,
    NotificationCenter
  },
  async beforeCreate() {
    this.$store = store
    await this.$store.dispatch('auth/init')
    if (this.isAuthenticated && this.$route.name === 'adminNotFound')
      this.$router.push('/admin/configuration')
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated
    }),
    showAdminHeader() {
      return (
        this.$route.name !== 'adminAuth' &&
        this.$route.name !== 'adminCreateUser' &&
        this.$route.name !== 'adminNotFound'
      )
    }
  }
}
</script>

<style lang="scss">
@import '../assets/main.scss';

.rootAdmin {
  display: flex;
  align-items: flex-start;
}

.adminNotificationCenter {
  position: fixed;
  top: 0;
  right: 0;
  width: 30vw;
  z-index: 5;
}
</style>
