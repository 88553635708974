<template>
  <div class="adminInput">
    <label v-if="label" :for="name">{{ label }}</label>
    <div :data-replicated-value="value">
      <textarea
        class="adminInput__textarea"
        :name="name"
        :placeholder="placeholder"
        :value="value"
        :required="required"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
        @invalid="required && $emit('invalid')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.adminInput {
  label,
  textarea {
    display: block;
    width: 100%;
    text-transform: uppercase;
  }

  textarea {
    padding: 0;
    resize: none;
    overflow: hidden;
  }
  div {
    display: grid;
    margin-top: var(--spacing-s);

    textarea,
    &::after {
      /* Identical styling required!! */
      border: 1px solid black;
      padding: 0.5rem;
      font: inherit;

      /* Place on top of each other */
      grid-area: 1 / 1 / 2 / 2;
    }
  }
  div::after {
    content: attr(data-replicated-value) ' ';
    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }

  label {
    margin-bottom: var(--spacing-xs);
    font-size: 8px;
  }

  &__textarea {
    border: 0;
    border-bottom: 1px solid black;
    font-weight: 800;
    font-size: var(--font-s);
    // padding: var(--spacing-s) 0;
    line-height: 1.5em;

    &::placeholder {
      text-transform: uppercase;
      font-weight: 800;
      color: var(--alpha-black-hover);
    }
  }
}
</style>
