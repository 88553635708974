<template>
  <div class="promptGroupDeletion">
    <p>Êtes-vous sûr de vouloir supprimer<br />le groupe "{{ groupName }}" ?</p>
    <e-button @click.native.prevent="$emit('confirm')">Confirmer</e-button>
    <e-button @click.native.prevent="$emit('cancel')">Annuler</e-button>
  </div>
</template>

<script>
export default {
  props: {
    groupName: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.promptGroupDeletion {
  background-color: var(--white);
  display: inline-block;
  padding: var(--spacing-xl);
  text-align: center;
  border: 1px solid var(--black);

  p {
    margin-top: 0;
    line-height: 1.5em;
  }
}
</style>
