import Vue from 'vue'
import Button from './components/elements/button'
import Link from './components/elements/link'
import Input from './components/elements/input'
import Textarea from './components/elements/textarea'
import Select from './components/elements/select'
import Checkbox from './components/elements/checkbox'
import UploadButton from './components/elements/upload-button'
import EditHeader from './components/elements/edit-header'
import EntityList from './components/block/entity-list'
import Popin from './components/block/popin'
import Page from './components/block/page'

Vue.component('e-button', Button)
Vue.component('e-link', Link)
Vue.component('e-input', Input)
Vue.component('e-textarea', Textarea)
Vue.component('e-select', Select)
Vue.component('b-entity-list', EntityList)
Vue.component('b-page', Page)
Vue.component('b-popin', Popin)
Vue.component('e-checkbox', Checkbox)
Vue.component('e-upload-button', UploadButton)
Vue.component('e-edit-header', EditHeader)
