<template>
  <div class="notificationCenter">
    <notice
      :type="notif.type"
      v-for="(notif, i) in $store.state.notifications.list"
      :key="i"
    >
      <p>{{ notif.text }}</p>
    </notice>
  </div>
</template>

<script>
import Notice from '../elements/notice'

export default {
  components: {
    Notice
  }
}
</script>
