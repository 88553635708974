<template>
  <div class="adminUploadButton">
    <p v-if="title.length > 0">{{ title }}</p>
    <label class="adminUploadButton__label">
      <div>
        <upload />
        <p>{{ text }}</p>
      </div>
      <input
        ref="input"
        :name="name"
        type="file"
        :accept="types"
        :multiple="multiple"
        class="adminUploadButton__input"
        @change="onChange"
      />
    </label>
  </div>
</template>

<script>
import Upload from '../svg/upload.svg'

export default {
  name: 'uploadButton',
  components: {
    Upload
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      default: 'file'
    },
    types: {
      type: String,
      required: true
    },
    // TODO: File Object to prefill input
    file: {
      type: Object,
      required: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    hasText: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      currentFile: null,
      filename: this.file ? this.file.name : null
    }
  },
  computed: {
    text() {
      return this.filename
        ? this.filename
        : this.hasText
        ? `Choisir ${this.multiple ? 'des fichiers' : 'un ficher'} (${
            this.types
          })`
        : ''
    }
  },
  methods: {
    onChange() {
      if (this.multiple) this.$emit('files', this.$refs.input.files)
      else {
        this.filename = this.$refs.input.value.split(/(\\|\/)/g).pop()
        this.$emit('change', this.$refs.input.files)
        this.$emit('input', this.$refs.input.files)
      }
    }
  }
}
</script>

<style lang="scss">
.adminUploadButton {
  &__label {
    cursor: pointer;

    div {
      align-items: center;
      display: flex;

      svg {
        flex-shrink: 0;
      }

      p {
        margin-left: 1em;
      }
    }

    &:hover svg path {
      &:first-child {
        fill: var(--black);
      }

      &:last-child {
        fill: var(--white);
      }
    }
  }
  &__input {
    display: none;
  }
}
</style>
