import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Book from '../views/book.vue'
import NotFound from '../views/not-found.vue'
import adminRoutes from '../admin/routes'
import adminLayout from '../admin/layouts/index'
import defaultLayout from '../layouts/default'
Vue.use(VueRouter)

const routes = [
  {
    path: '/admin',
    name: 'Admin',
    component: adminLayout,
    children: adminRoutes
  },
  {
    path: '/',
    name: 'Front',
    component: defaultLayout,
    children: [
      {
        path: '',
        name: 'Index',
        component: Index
      },
      {
        path: ':version',
        name: 'Book',
        component: Book
      }
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// console.log(store.state)
// router.beforeEach((to, from, next) => {
//   if (from.hash === 'display') {
//     store.commit('setPopinOpen', false)
//     console.log(store.state.isPopinOpen)
//   }
//   next()
// })

export default router
