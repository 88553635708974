<template>
  <form action="">
    <e-input
      label=""
      v-model="email"
      type="email"
      name="email"
      placeholder="email"
    />
    <e-input
      label=""
      v-model="firstName"
      type="text"
      name="first_name"
      placeholder="first name"
    />
    <e-input
      label=""
      v-model="lastName"
      type="text"
      name="last_name"
      placeholder="last name"
    />
    <e-input
      label=""
      v-model="password"
      type="password"
      name="password"
      placeholder="password"
    />
    <e-button
      to="/"
      @click.native.prevent="submit"
      class="adminAuth__link"
      layout="link-arrow"
      >S'inscrire</e-button
    >
  </form>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      password: ''
    }
  },
  methods: {
    submit() {
      this.$emit('submit', {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password
      })
    }
  }
}
</script>
