<template>
  <header class="adminHeader">
    <LogoSvg class="adminHeader__logo" />
    <Menu v-if="menu" />
  </header>
</template>

<script>
import LogoSvg from '@/components/svg/logo.svg'
import Menu from './menu'

export default {
  props: {
    menu: {
      type: Boolean,
      default: true
    }
  },
  components: {
    LogoSvg,
    Menu
  }
}
</script>

<style lang="scss" scoped>
.adminHeader {
  padding: var(--spacing-xl) var(--spacing-l);
  position: sticky;
  top: 0;

  &__logo {
    margin-bottom: 25px;
  }
}
</style>
