export default {
  namespaced: true,
  state: {
    gridModale: false
  },
  mutations: {
    initGrid(state) {
      state.gridModale = false
    },
    toggleGridModale(state) {
      state.gridModale = !state.gridModale
    },
    startPending(_, item = document.body) {
      item.classList.add('loading')
    },
    stopPending(_, item = document.body) {
      item.classList.remove('loading')
    }
  }
}
