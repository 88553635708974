<template>
  <label :class="{ 'select--disabled': disabled }" class="select">
    <span v-if="label.length > 0">{{ label }}</span>
    <select
      ref="select"
      class="select__input"
      :name="name"
      :disabled="disabled"
      :required="required"
      @input="$emit('input', $event.target.value)"
    >
      <slot />
    </select>
    <arrow-down />
  </label>
</template>

<script>
import ArrowDown from '../svg/arrow-down.svg'

export default {
  components: {
    ArrowDown
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.select {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--black);
  flex-wrap: wrap;
  position: relative;

  span {
    width: 100%;
    margin-bottom: var(--spacing-xs);
    font-size: 8px;
  }

  svg {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: calc(50% + 4px);
    transform: translateY(-50%);
  }

  select {
    appearance: none;
    border: none;
    flex-grow: 1;
    font-weight: 800;
    font-size: var(--font-s);
    line-height: 3em;
    text-transform: uppercase;
  }
}
</style>
