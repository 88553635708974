<template>
  <div class="promptMediaFileSelection">
    <h3 class="e-h1">Sélectionner un ou des fichier(s) associé(s)</h3>
    <h4 class="">Media original (requis)</h4>
    <e-upload-button
      name="medias"
      types=".png,.jpg,.webp,.mp4,.webm"
      v-model="originalFile"
      class="promptMediaFileSelection__uploadButton"
    />
    <h4 class="">Media visible dans la grille (optionnel)</h4>
    <e-upload-button
      name="medias"
      types=".png,.jpg,.webp,.mp4,.webm"
      v-model="gridFile"
      class="promptMediaFileSelection__uploadButton"
    />
    <h4 class="">Media visible dans la pop-in (optionnel)</h4>
    <e-upload-button
      name="medias"
      types=".png,.jpg,.webp,.mp4,.webm"
      v-model="popinFile"
      class="promptMediaFileSelection__uploadButton"
    />
    <footer class="promptMediaFileSelection__footer">
      <e-button
        :disabled="!originalFile"
        @click.native.prevent="onConfirmSelection"
        >Confirmer</e-button
      >
      <e-button @click.native.prevent="$emit('cancel')">Annuler</e-button>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      originalFile: null,
      gridFile: null,
      popinFile: null
    }
  },
  methods: {
    onConfirmSelection() {
      this.$emit('confirm', [
        this.originalFile[0],
        this.gridFile && this.gridFile[0],
        this.popinFile && this.popinFile[0]
      ])
    }
  }
}
</script>

<style lang="scss">
.promptMediaFileSelection {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: var(--spacing-xl);
  background-color: var(--white);

  h3 {
    margin-bottom: 25px;
  }

  // &__filters {
  //   display: flex;
  //   margin-bottom: var(--spacing-l);
  // }

  // &__select {
  //   width: 20%;
  //   min-width: 200px;
  // }

  // &__select + &__select {
  //   margin-left: var(--spacing-xl);
  // }

  &__mediaList {
    margin-bottom: var(--spacing-xl);
    // max-height: 400px;
  }

  &__footer {
    text-align: center;
    margin-top: 40px;
  }
}
</style>
