export default {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
    add(state, payload) {
      state.list.push(payload)
    },
    shift(state) {
      state.list.shift()
    }
  },
  actions: {
    present({ commit }, notif) {
      commit('add', notif)

      setTimeout(() => {
        commit('shift')
      }, 5000)
    }
  }
}
