<template>
  <form @submit.prevent="submit">
    <e-input v-model="email" type="email" name="email" placeholder="email" />
    <e-input
      v-model="password"
      type="password"
      name="password"
      placeholder="password"
    />
    <e-button type="submit" class="adminAuth__link" arrow="after"
      >Se Connecter</e-button
    >
  </form>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    submit() {
      this.$emit('submit', { email: this.email, password: this.password })
    }
  }
}
</script>
