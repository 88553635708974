import fetcher from '../assets/scripts/fetcher'

export default {
  namespaced: true,
  state: {
    medias: []
  },

  mutations: {
    updateMedias(state, medias) {
      state.medias = medias.map(m => {
        if (m.name === 'null') {
          m.name = ''
        }
        return m
      })
    }
  },

  actions: {
    async fetch({ commit }, password) {
      const { data } = await fetcher.get('/medias/auth', {
        params: { password }
      })
      commit('updateMedias', data)
      return data
    }
  }
}
