<template>
  <div class="adminCheckbox">
    <label class="adminCheckbox__label">
      <input
        ref="input"
        class="adminCheckbox__input"
        type="checkbox"
        :checked="value"
        @change="$emit('input', $event.target.checked)"
      />
      <span class="adminCheckbox__check"><check /></span>
      <span class="adminCheckbox__text">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import Check from '../svg/check.svg'

export default {
  components: {
    Check
  },
  props: {
    value: {
      type: [Boolean, Number],
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.adminCheckbox {
  &__label {
    align-items: center;
    display: flex;
  }

  &__input {
    display: none;
  }

  &__check {
    display: block;
    width: 14px;
    height: 14px;
    border: 1px solid var(--black);
    text-align: center;

    svg {
      opacity: 0;
    }
  }

  &__input:checked + &__check svg {
    opacity: 1;
  }

  &__text {
    margin-left: 0.5em;
  }
}
</style>
