import Login from './views/login'
import SignUp from './views/signup'
import Groups from './views/groups/index'
import GroupsEdit from './views/groups/edit'
import Versions from './views/versions/index'
import Grid from './views/versions/edit'
import Medias from './views/medias/index'
import MediasUp from './views/medias/upload'
import Configuration from './views/configuration'

import { authGuard, authBypass } from './scripts/guards'

export default [
  {
    path: 'login',
    name: 'adminAuth',
    component: Login,
    beforeEnter: authBypass,
    meta: { layout: 'empty-layout' }
  },
  {
    path: 'signup',
    name: 'adminCreateUser',
    component: SignUp,
    meta: { layout: 'empty-layout' }
  },
  {
    path: 'configuration',
    name: 'adminConfiguration',
    component: Configuration,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: 'Configuration' }
  },
  {
    path: 'groups',
    name: 'adminGroups',
    component: Groups,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: 'Groupes' }
  },
  {
    path: 'groups/edit',
    name: 'adminGroupsCreate',
    component: GroupsEdit,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: '' }
  },
  {
    path: 'groups/edit/:id',
    name: 'adminGroupsEdit',
    component: GroupsEdit,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: '' }
  },
  {
    path: 'medias',
    name: 'adminMedias',
    component: Medias,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: 'Medias' }
  },
  {
    path: 'medias/upload',
    name: 'adminMediasUp',
    component: MediasUp,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: '' }
  },
  {
    path: 'versions',
    name: 'adminVersions',
    component: Versions,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: 'Versions' }
  },
  {
    path: 'versions/edit',
    name: 'adminVersionsCreate',
    component: Grid,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: '' }
  },
  {
    path: 'versions/edit/:id',
    name: 'adminVersionsEdit',
    component: Grid,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: '' }
  },
  {
    path: '',
    name: 'adminNotFound',
    component: Login,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: '' }
  },
  {
    path: '*',
    name: 'adminNotFound',
    component: Versions,
    beforeEnter: authGuard,
    meta: { layout: 'default-layout', menuName: '' }
  }
]
