import axiosAdmin from '../scripts/axios-admin'

export default {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
    setVersionList(state, payload) {
      state.list = payload
    }
  },
  actions: {
    async fetch({ commit }) {
      const { data } = await axiosAdmin.get('/versions')
      commit('setVersionList', data)
    },
    async deleteItem({ dispatch }, id) {
      await axiosAdmin.delete(`/versions/${id}`)
      dispatch('fetch')
    }
  }
}
