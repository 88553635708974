import axiosAdmin from '../scripts/axios-admin'

export default {
  namespaced: true,
  state: {
    list: []
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
    }
  },
  actions: {
    async fetch({ commit }) {
      const { data } = await axiosAdmin.get('/groups')
      commit('setList', data)
    },
    async deleteItem({ dispatch }, params) {
      await axiosAdmin.delete(`/groups/${params.id}`)
      dispatch('fetch')
    }
  }
}
