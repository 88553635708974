import axios from 'axios'

const fetcher = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  header: {
    'Content-Type': 'application/json'
  }
})
const token = localStorage.getItem('Bearer') || false

if (token) {
  fetcher.defaults.headers.common.Authorization = `Bearer ${token}`
}

export default fetcher
