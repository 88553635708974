import 'intersection-observer'
import Vue from 'vue'
import store from './store'
import VueYoutube from 'vue-youtube'
import App from './App.vue'
// import "./registerServiceWorker";
import router from './router'
import Viewport from '@/plugins/viewport'
import './assets/scss/main.scss'
import './admin/components'
// import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false

Vue.use(VueYoutube)
Vue.use(Viewport)

// Vue.use(VueAnalytics, {
//   id: "G-D4B8X4PJ1W",
//   disabled: true
// });

Vue.prototype.$bus = new Vue()

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

document.addEventListener('dragstart', e => {
  e.preventDefault()
  return false
})
;(async () => {
  await store.dispatch('configuration/fetch')
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})()
