<template>
  <GridItem
    class="gridYoutube"
    :hoverable="false"
    :data="content"
    is-youtube
    :admin="admin"
  >
    <div
      :class="{ 'gridYoutube__wrapper--visible': loaded }"
      class="gridYoutube__wrapper"
      style="--aspect-ratio: calc(16/9);"
    >
      <youtube
        :video-id="youtubeId"
        @playing="() => (playing = true)"
        @ready="onReady"
        ref="youtube"
      />
    </div>
  </GridItem>
</template>

<script>
import GridItemMixin from '@/components/mixins/grid-item'
import { youtubeParser } from '../../assets/scripts/youtube-parser'

export default {
  name: 'GridYoutube',
  mixins: [GridItemMixin],
  props: {
    admin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loaded: false,
      playing: false,
      muted: true,
      copied: false
    }
  },

  computed: {
    youtubeId() {
      return youtubeParser(this.content.media.url)
    }
  },
  methods: {
    onReady() {
      setTimeout(() => {
        this.loaded = true
        this.$emi('loaded')
      }, 0)
    }
  }
}
</script>

<style lang="scss">
.gridYoutube {
  $self: &;
  position: relative;
  cursor: inherit;

  &__wrapper {
    height: 100%;
    transition: opacity 250ms linear;
    opacity: 0;

    &--visible {
      opacity: 1;
    }
  }

  video {
    cursor: pointer;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 17px;
    opacity: 1;
    transition: opacity 250ms linear;
    color: white;
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    // justify-content: space-between;

    @media (hover: hover) {
      opacity: 0;

      #{$self}:hover & {
        opacity: 1;
      }
    }

    p,
    a,
    button {
      color: inherit;
    }

    p {
      margin: 0 21px;

      a,
      button {
        text-decoration: underline;
      }
    }

    button {
      background: none;
      border: unset;
      padding: 0;
      font-size: inherit;
    }
  }
}
</style>
