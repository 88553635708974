<template>
  <section class="notFoundPage">
    <main class="notFoundPage__main">
      <p>
        SORRY, WE COULDN’T FIND THE PAGE YOU ARE LOOKING FOR.<br />
        IT MAY HAVE BEEN REMOVED, CHANGED OR IS TEMPORARILY UNAVAILABLE.
      </p>
      <p>PLEASE RETURN TO OUR HOME PAGE TO CONTINUE BROWSING OUR SITE.</p>
      <p><router-link to="/">BACK TO HOME</router-link></p>
    </main>
    <PageFooter />
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import PageFooter from '@/components/component/page-footer'

export default {
  name: 'NotFound',
  components: {
    PageFooter
  },
  computed: {
    ...mapState({
      siteName: state => state.configuration.siteName
    })
  },
  created() {
    this.setTheme('light')
  },
  mounted() {
    document.title =
      this.siteName
        .split('<br />')
        .join(' ')
        .toUpperCase() + ' - Saint Laurent'
  },
  methods: {
    ...mapMutations({
      setTheme: 'setTheme'
    })
  }
}
</script>

<style lang="scss">
.notFoundPage__main {
  padding: 17px;

  @media screen and (min-width: 768px) {
    padding: 0 30px 30px 30px;
  }

  p {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: -0.015em;
  }
}
</style>
