<template>
  <transition name="popin">
    <div class="adminNotice" v-if="isVisible">
      <h2>{{ type }}</h2>
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    }
  },
  props: {
    type: {
      type: String,
      default: 'notice'
    }
  },
  mounted() {
    this.isVisible = true
  }
}
</script>

<style lang="scss">
.adminNotice {
  padding: var(--spacing-s) var(--spacing-l);
  margin: var(--spacing-s);
  border: 1px solid var(--black);
  background: var(--white);
}

.popin-enter-active,
.popin-leave-active {
  transition: opacity 0.5s;
}
.popin-enter, .popin-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
