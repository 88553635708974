import store from '../store'

export const authGuard = async (from, to, next) => {
  await store.dispatch('auth/init')

  if (!store.state.auth.isAuthenticated) next('/admin/login')
  else next()
}

export const authBypass = async (from, to, next) => {
  await store.dispatch('auth/init')

  if (store.state.auth.isAuthenticated) next('/admin/configuration')
  else next()
}
